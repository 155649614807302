import React, { useState } from 'react';
import { connect } from 'react-redux';
import { resetEmail } from '../../../actions/userActions';
import {
  Container, Title, Label, Image, Form, FormHeading, Button,
  InputContainer,
} from './styled';
import background from './background_image.png';
const label = 'We’ll get you back into your account soon! ';

function ResetPassword({ resetEmail }) {
  const [email, setEmail] = useState(null);
  return (
    <Container>
      <Image src={background} />
      <Title>RESET MY PASSWORD</Title>
      <Label>{label}</Label>
      <Form>
        <FormHeading>What email address did you use to register?</FormHeading>
        <InputContainer>
          <input placeholder="Enter your email here..." value={email} onChange={({ target: { value } }) => setEmail(value)} />
        </InputContainer>
      </Form>
      <Button onClick={() => resetEmail(email)}>Send me a reset link</Button>
    </Container>
  );
}

const mapDispatchToProps = { resetEmail };

export default connect(null, mapDispatchToProps)(ResetPassword);
