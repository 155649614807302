import styled from 'styled-components';

export const Container = styled.div`
> div {
  position: relative;
  > img {
    object-fit: cover;
    width: 100vw;
    display: flex;
  }
}
`;

export const Body = styled.div`
  position: absolute;
  top: 50%;
  left: 63%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const Title = styled.div`
  font-family: SilverSouthSerif;
  font-size: 68px;
  color: #124C56;
  letter-spacing: 0;
`;

export const SubHeading = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 28px;
  color: #124C56;
  letter-spacing: 0;
  line-height: 48px;
  margin-bottom: 23px;
`;