import React, { useEffect } from 'react';
import { connect } from 'react-redux';
//components
import Layout from '../components/Layout';
import ResetPasswordMobile from '../components/Mobile/ResetPassword';
import ResetPasswordDesktop from '../components/Desktop/ResetPassword';
//actions
import { setMetaTags } from '../actions/homeActions';
//styles
import './style.css';

function ResetPassword({ setMetaTags }) {
  useEffect(() => {
    const description = 'Reset your password';
    const image = '';
    const url = window.location.href;
    const title = `SoBridely`;

    setMetaTags({
      description, image, url, title,
    });
  }, [setMetaTags]);
  return (
    <Layout
      mobile={<ResetPasswordMobile />}
      desktop={<ResetPasswordDesktop />} />
  );
}

const mapDispatchToProps = { setMetaTags };

export default connect(null, mapDispatchToProps)(ResetPassword);