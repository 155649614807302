import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  padding: 0 6.563vw;
  height: 180.3vw;
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100vw;
  display: flex;
  position: absolute;
  z-index: -1;
  left: 0;
`;

export const Title = styled.div`
  font-family: SilverSouthSerif;
  font-size: 10.00vw;
  color: #000000;
  letter-spacing: 0;
  line-height: 15.00vw;
  padding-top: 7.500vw;
`;

export const Label = styled.div`
  font-family: sofia-pro, sans-serif; 
  font-weight: 700;
  font-size: 5.625vw;
  color: #464855;
  letter-spacing: 0;
  line-height: 8.125vw;
  margin-top: 4.375vw;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4.063vw;
`;

export const FormHeading = styled.div`
  height: 15.00vw;
  background-color: #E1CFCD;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 4.375vw;
  color: #464855;
  letter-spacing: 0;
  line-height: 5.000vw;
  width: 66.88vw;
  padding: 0 10.00vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InputContainer = styled.div`
  height: 15.00vw;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 4.375vw;
  input {
    opacity: 0.48;
    font-family: sofia-pro, sans-serif;
  font-weight: 400;
    font-size: 5.313vw;
    color: #464855;
    letter-spacing: 0;
    line-height: 6.875vw;
  }
`;

export const Button = styled.div`
  margin-top: 15.00vw;
  background-color: #FE95A2;
  border-radius: 2.813vw;
  height: 9.688vw;
  width: 100%;
  font-family: sofia-pro, sans-serif; 
  font-weight: 700;
  font-size: 5.625vw;
  color: #FFFFFF;
  letter-spacing: 0.69px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
`;