import React, { useState } from 'react';
import { connect } from 'react-redux';
import Button from '../../Button1';
import { resetEmail } from '../../../actions/userActions';
import Input from '../../ThemedInput';
import {
  Container, Body, Title, SubHeading,
} from './styled';
import background from './background_image.png';
const label = 'Enter the email you used to register.';

function Login({ resetEmail }) {
  const [email, setEmail] = useState(null);
  return (
    <Container>
      <div>
        <img src={background} />
        <Body>
          <Title>RESET MY PASSWORD</Title>
          <SubHeading>We'll get you back into your account soon!</SubHeading>
          <Input style={{ width: 586 }} placeholder={label} value={email} update={setEmail}/>
          <Button onClick={() =>resetEmail(email)} color="#7DBEC8" style={{
            height: 60, width: 586, margin:'30px 0 0',
          }}>Send My A Reset Link</Button>
        </Body>
      </div>
    </Container>
  );
}

const mapDispatchToProps = { resetEmail };

export default connect(null, mapDispatchToProps)(Login);
